import Head from "next/head";
import React from "react";
import css from "./index.module.scss";
import Button from "@/components/button";
import Header from "sections/header/index.jsx";
import Footer from "sections/footer";
import { useUser } from "scripts/apis/x/useUser";

export default function FourOhFourPage({ }) {
  const description = "The page you were looking for could not be found";
  const title = "404 | X";
  const user = useUser();

  return (
    <div className={`root ${css.root}`}>
      <Head>
        <title>{title}</title>
        <meta name="description" content={description} />
        <meta property="og:description" content={description} />
        <meta property="og:title" content={title} />
      </Head>
      <Header/>
      <main>

        <section className={css.error}>
          <div className="sectionInner">
            <div className={css.wrapper}>
              <div className={css.row}>
                <div className={css.status}>404</div>
                <div className={css.message}>This page does not exist</div>
              </div>

              <Button className={css.button} href="/" label="Find Challenges" />
              <Button className={css.button} href="/" ghost grayscale label="Rules" />
              {!user.value && <Button className={css.button} href="/" ghost grayscale label="Create Account" />}
              {user.value && <Button className={css.button} href="/profile" ghost grayscale label="Profile" />}

            </div>
          </div>
        </section>
      </main>
      <Footer />

    </div>
  );
}
